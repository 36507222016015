import React, { useState } from 'react';
import './Form.css';

const Form = () => {
  const [number, setNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (number >= 40 && number <= 50) {
      window.open('https://www.youtube.com/watch?v=your_correct_link', '_blank');
    } else {
      window.open('https://www.youtube.com/watch?v=your_incorrect_link', '_blank');
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <label>
          Enter a number between 40 and 50:
          <input
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Form;

