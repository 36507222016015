import React, { useState } from 'react';
import './App.css';
import './index.css';
import spookyImage from './assets/Logo.png';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Form from './Form';
import Wheel from './Wheel'; // Import the Wheel component




const App = () => {
  const [number, setNumber] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Define the correct and incorrect YouTube links
    const correctYouTubeLink = 'https://www.youtube.com/watch?v=wOHNEerlmZE'; // Example: Rick Astley - Never Gonna Give You Up
    const incorrectYouTubeLink = 'https://www.youtube.com/watch?v=9llTW2E47Bk'; // Example: Rickroll

    // Define the correct range
    const minNumber = 55;
    const maxNumber = 57;

    // Play the click sound
    document.getElementById('click-sound').play();

    // Check if the number is within the correct range
    const userNumber = parseInt(number, 10);
    if (userNumber >= minNumber && userNumber <= maxNumber) {
      // Redirect to the correct YouTube URL if the number is within range
      window.open(correctYouTubeLink, '_blank'); // Opens in a new tab or window
    } else {
      // Redirect to the incorrect YouTube URL if the number is out of range
      window.open(incorrectYouTubeLink, '_blank'); // Opens in a new tab or window
    }
  };

  return (
    <div className="App">
      <img src={spookyImage} alt="Spooky" className="spooky-image" />
      <h1 className="typewriter">What Will Your Fate Be?</h1>
      <form onSubmit={handleSubmit}>
      <label>Enter Your Survival Score: <input type="number" value={number} onChange={(e) => setNumber(e.target.value)} required /></label>
        <button type="submit">Submit</button>
      </form>
       {/* Hidden audio element for playing sound */}
       <audio id="click-sound" src="/Ghost.wav" />
       
    </div>
  );
};


export default App;

